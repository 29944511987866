<template>
  <v-container fluid>
    <v-card class="mb-3" v-if="filter">
      <v-card-actions class="py-0 px-5">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="search" prepend-icon="fa-search" label="Meklēt" single-line hide-details clearable/>
          </v-col>
          <v-col cols="12" md="2">
            <v-select label="Statuss" prepend-icon="fa-toggle-on" v-model="status" :items="statuses" item-text="text" item-value="value" clearable/>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>Uzņēmumi</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon :to="{name: 'companyCreate'}" color="primary">
          <v-icon>fa-plus</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="filter = !filter">
          <v-icon>fa-sliders-h</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="companies"
        item-key="item.id"
        :loading="loading"
        loading-text="Notiek ielāde... Lūdzu, uzgaidiet"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 50, 100, -1]
        }"
        :items-per-page="50"
      >
        <template v-slot:item.color="{ item }">
          <v-sheet width="20" height="20" :style="{backgroundColor:item.color}"></v-sheet>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip small :color="statusColor(item.status)" v-html="statusText(item.status)"></v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="primary" :to="{name: 'companyShow', params: {id: item.id}}">
            <v-icon>fa-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'

export default {
  name: 'CompanyIndex',
  data: () => ({
    filter: false,
    loading: true,
    search: '',
    status: null,
    ads: null,
    tasks: null,
    companies: []
  }),
  computed: {
    statuses () {
      return this.$store.state.statuses
    },
    headers () {
      return [
        {
          text: 'Statuss',
          value: 'status',
          filter: value => {
            if (this.status === 0 || this.status === 1) {
              return value === this.status
            }
            if (!this.status) return true
          }
        },
        {
          text: 'Nosaukums',
          value: 'name'
        },
        {
          text: 'Tālrunis',
          value: 'phone',
          sortable: false
        },
        {
          text: 'E-pasts',
          value: 'email',
          sortable: false
        },
        {
          text: 'Direktors',
          value: 'director',
          sortable: false
        },
        {
          text: 'Budžets',
          value: 'budget'
        },
        {
          text: 'Budžeta datums',
          value: 'budget_date'
        },
        {
          text: 'Krāsa',
          value: 'color',
          sortable: false
        },
        {
          text: 'Reklāmas',
          value: 'advertisements_count'
        },
        {
          text: 'Izveidots datums',
          value: 'created_at'
        },
        {
          text: 'Darbības',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    getCompanies () {
      axios.get('companies').then(response => {
        this.companies = response.data.data
        this.loading = false
      })
    }
  },
  created () {
    this.getCompanies()
  }
}
</script>

<style scoped>

</style>
