var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.filter)?_c('v-card',{staticClass:"mb-3"},[_c('v-card-actions',{staticClass:"py-0 px-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-icon":"fa-search","label":"Meklēt","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"label":"Statuss","prepend-icon":"fa-toggle-on","items":_vm.statuses,"item-text":"text","item-value":"value","clearable":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1)],1)],1):_vm._e(),_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Uzņēmumi")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","to":{name: 'companyCreate'},"color":"primary"}},[_c('v-icon',[_vm._v("fa-plus")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.filter = !_vm.filter}}},[_c('v-icon',[_vm._v("fa-sliders-h")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.companies,"item-key":"item.id","loading":_vm.loading,"loading-text":"Notiek ielāde... Lūdzu, uzgaidiet","search":_vm.search,"footer-props":{
        'items-per-page-options': [10, 50, 100, -1]
      },"items-per-page":50},scopedSlots:_vm._u([{key:"item.color",fn:function(ref){
      var item = ref.item;
return [_c('v-sheet',{style:({backgroundColor:item.color}),attrs:{"width":"20","height":"20"}})]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.statusColor(item.status)},domProps:{"innerHTML":_vm._s(_vm.statusText(item.status))}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","to":{name: 'companyShow', params: {id: item.id}}}},[_c('v-icon',[_vm._v("fa-eye")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }